/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #21dbdb;
  --error: #e7195a;
}
body,
header {
  background-color: rgba(49, 47, 47, 0.846);
  background-image: url(./img/truck.jpg);
  background-blend-mode: inherit;
  margin: 0;
  font-family: 'Poppins';
  box-sizing: border-box;
}
html {
  position: relative;
  height: 100vh;
  max-width: 100vw;
}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}
header a {
  color: #fff;
  text-decoration: none;
}
.pages {
  max-width: 100vw;
  padding: 1%;
  margin: 0;
}
.loginButton {
  color: #000;
}

/* homepage */
.home {
  display: flex;
  justify-content: space-between;
}

/* new trip form */
label {
  color: #fff;
}
label::placeholder {
  color: #fff;
}

input {
  display: block;
  opacity: 0.5;
  background-color: #333;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
input::placeholder {
  color: #fff;
}

form button {
  background: transparent;
  border: solid 2px var(--primary);
  color: #fff;
  padding: 10px;
  font-family: 'Poppins';
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
}
form button:hover {
  background-color: var(--primary);
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
  background-color: #cf4141;
}

/* Navbar */
nav {
  display: flex;
  align-items: center;
}
nav span {
  color: #fff;
}
nav a {
  margin-left: 10px;
}
nav button {
  background: transparent;
  color: #fff;
  border: 2px solid var(--primary);
  margin-left: 10px;
  padding: 6px 10px;
  border-radius: 4px;
  font-family: 'Poppins';
  cursor: pointer;
  font-size: 1rem;
}

/* Auth forms */
form.login,
form.signup {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

@media screen and (max-width: 580px) {
  html,
  body {
    max-width: 100%;

    overflow-x: hidden;
  }
  .pages {
    max-width: 600px;
    padding: 0 5px 0 5px;
    margin: 0;
  }
  .home {
  }

  .trip-details {
    max-width: 100%;
    margin-left: 1% !important;
  }
  .trip-details h4 {
    margin: 0 0 5px 0;
    font-size: 1em;
  }
  .trip-details p {
    font-size: 0.8rem;
    color: #eee;
  }
  .trip-details span {
    position: absolute;
    top: 80%;
    right: 5%;
    cursor: pointer;
    background: #f1f1f1;
    padding: 6px;
    border-radius: 50%;
    color: #333;
  }
  .tripAddButton {
    position: relative;
    z-index: 3;
  }

  .credentials {
    transform: translatex(10%);
    display: block;
  }
  .loginButton {
    color: #000;
  }
  nav button {
    padding: 3px 5px;
  }
}
