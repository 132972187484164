$primary: #21dbdb;
.add-trip-container {
  position: relative;
  flex: 1.2;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .date-picker-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 0.5rem;
  }
  label[data-shrink='false']
    + .MuiInputBase-formControl
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding-bottom: 12px;
    margin-top: 0;
  }
  .create {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    box-sizing: border-box;
    max-width: 100%;
    top: 15%;
    margin-right: 0;
    box-sizing: border-box;
    input {
      color: #fff;
    }
  }

  .hide {
    display: none;
  }
  .expandButton {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    text-align: right;
    width: fit-content;
    bottom: 0.5rem;
    button {
      position: relative;
      top: 0;
      border: 4px solid $primary;
      background-color: transparent;
      scale: 80%;
      color: $primary;
      z-index: 3;
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
  .driver-stats {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: left;
    text-shadow: 0px 0px 4px #181818;
    overflow-y: auto;
    margin-top: 10px;

    p {
      color: #fff;
      &:last-child {
        margin-bottom: 4rem;
      }
    }
    span {
      color: $primary;
      font-weight: bold;
    }
    h3 {
      text-align: center;
      color: $primary;
    }
  }
}
.millage {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  .half {
    display: block;
    width: 49%;
    float: left;
    box-sizing: border-box;
    color: #fff;
  }
}
@media screen and (max-width: 640px) {
  .millage {
    flex-direction: column;
    input {
      &:first-child {
        margin-bottom: 0;
      }
      &:last-child {
        margin-top: 0;
      }
    }
  }
  .half {
    min-width: 100%;
  }
  .expandButton {
    scale: 80%;
  }
  .driver-stats {
    position: relative;
    scale: 80%;
    padding: 0;
    top: -10%;
    margin: 0;
    overflow-y: auto;
    p {
      margin: 5px;
      font-size: 0.9rem;
    }
  }
}
